/********************************
 * AUTO-GENERATED. DO NOT EDIT. *
 ********************************/
/*
 *
 * All **toNodeId** and **fromNodeId** functions are extracted
 * from the ts files in the schema folder. To regenerate this file,
 * run the following command:
 *
 *  > yarn gql generate
 *
 */
import { toGlobalId, fromGlobalId, ID } from "./id";
import { pipe } from "fp-ts/function";
export const toAllSystemsReportId = (
  reportType: string,
  customerId: string,
  uniqueIdentifier: string
) => toGlobalId("AllSystemsReport", reportType, customerId, uniqueIdentifier);

export const fromAllSystemsReportId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  reportType: fromGlobalId(id)[1],
  customerId: fromGlobalId(id)[2],
  uniqueIdentifier: fromGlobalId(id)[3],
});

export const toAreaId = (
  systemId: string | number,
  areaNumber: string | number,
  isNew?: boolean
) =>
  !!isNew
    ? toGlobalId(
        "Area",
        systemId,
        Number(areaNumber),
        Date.now() +
          (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      )
    : toGlobalId("Area", systemId, Number(areaNumber));

export const fromAreaId = (id: ID) => ({
  type: "Area",
  systemId: fromGlobalId(id)[1],
  number: fromGlobalId(id)[2],
});

export const toAreaInformationId = (
  systemId: string | number,
  areaNumber: string | number
) => toGlobalId("AreaInformation", systemId, areaNumber);

export const fromAreaInformationId = (id: ID) => ({
  type: "AreaInformation",
  systemId: fromGlobalId(id)[1],
  number: fromGlobalId(id)[2],
});

export const toTwoFactorSettingsId = (userId: string | number) =>
  toGlobalId("TwoFactorSettings", userId);

export const fromTwoFactorSettingsId = (id: ID) => ({
  type: "TwoFactorSettings",
  userId: fromGlobalId(id)[1],
});

export const toBatchJobId = (customerId: string, batchGroupId: number) =>
  toGlobalId("BatchJob", customerId, batchGroupId);

export const fromBatchJobId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  customerId: Number(fromGlobalId(id)[1]),
  batchGroupId: Number(fromGlobalId(id)[2]),
});

export const toBellOptionsId = (systemId: number | string) =>
  toGlobalId("BellOptions", Number(systemId));

export const fromBellOptionsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toCardFormatId = (
  customerId: string | number,
  systemId: string | number,
  cardNumber: string | number
) =>
  toGlobalId(
    "CardFormat",
    customerId,
    systemId,
    cardNumber,
    Date.now() + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  );

export const fromCardFormatId = (id: ID) => ({
  type: "CardFormat",
  customerId: fromGlobalId(id)[1],
  systemId: fromGlobalId(id)[2],
  cardNumber: fromGlobalId(id)[3],
});

export const toCentralStationAutomationIntegrationId = ({
  dealerId,
  id,
}: {
  dealerId: string | number;
  id: string | number;
}) => toGlobalId("CentralStationAutomationIntegration", dealerId, id);

export const fromCentralStationAutomationIntegrationId = (id: ID) => ({
  type: "CentralStationAutomationIntegration",
  dealerId: fromGlobalId(id)[1],
  id: fromGlobalId(id)[2],
});

export const toControlSystemCentralStationAutomationIntegrationId = (
  systemId: string | number
) => toGlobalId("ControlSystemCentralStationAutomationIntegration", systemId);

export const fromControlSystemCentralStationAutomationIntegrationId = (
  id: ID
) => ({
  type: "ControlSystemCentralStationAutomationIntegration",
  systemId: fromGlobalId(id)[1],
});

export const toEmergencyContactId = (
  centralStationName: string,
  systemId: string | number,
  order: number
) => toGlobalId("EmergencyContact", centralStationName, systemId, order);

export const fromEmergencyContactId = (id: ID) => ({
  type: "EmergencyContact",
  centralStationName: fromGlobalId(id)[1],
  systemId: fromGlobalId(id)[2],
  order: fromGlobalId(id)[3],
});

export const toControlSystemCentralStationAutomationIntegrationAuthorityLevelId =
  (centralStationName: string, code: string) =>
    toGlobalId(
      "ControlSystemCentralStationAutomationIntegrationAuthorityLevel",
      centralStationName,
      code
    );

export const fromControlSystemCentralStationAutomationIntegrationAuthorityLevelId =
  (id: ID) => ({
    type: "ControlSystemCentralStationAutomationIntegrationAuthorityLevel",
    centralStationName: fromGlobalId(id)[1],
    code: fromGlobalId(id)[2],
  });

export const toCentralStationGlobalPasscodeId = (
  centralStationName: string,
  systemId: string | number,
  randomUuid: string
) =>
  toGlobalId(
    "CentralStationGlobalPasscode",
    centralStationName,
    systemId,
    randomUuid
  );

export const fromCentralStationGlobalPasscodeId = (id: ID) => ({
  type: "CentralStationGlobalPasscode",
  centralStationName: fromGlobalId(id)[1],
  systemId: fromGlobalId(id)[2],
  order: Number(fromGlobalId(id)[3]),
});

export const toCombinedEventId = (
  systemId: string | number,
  eventId: string | number
) => toGlobalId("CombinedEvent", systemId, eventId);

export const fromCombinedEventId = (id: ID) => ({
  type: "CombinedEvent",
  systemId: fromGlobalId(id)[1],
  eventId: fromGlobalId(id)[2],
});

export const toCommunicationPathId = (
  systemId: number | string,
  number: number | string
) => {
  const args = [
    "CommunicationPath",
    systemId,
    Number(number),
    Date.now() +
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
  ];

  return toGlobalId(...args);
};

export const fromCommunicationPathId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
    number: Number(number),
  };
};

export const toCommunicationId = (systemId: number | string) =>
  toGlobalId("Communication", Number(systemId));

export const fromCommunicationId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toContactId = (
  customerId: string | number,
  contactId: string | number
) => toGlobalId("Contact", customerId, contactId);

export const fromContactId = (id: ID) => ({
  type: "Contact",
  customerId: fromGlobalId(id)[1],
  contactId: fromGlobalId(id)[2],
});

export const toControlSystemId = (systemId: string | number) =>
  toGlobalId("ControlSystem", systemId);

export const fromControlSystemId = (id: ID) => ({
  type: "ControlSystem",
  systemId: fromGlobalId(id)[1],
});

export const toControlSystemGroupId = (dbId: string | number) =>
  toGlobalId("ControlSystemGroup", dbId);

export const fromControlSystemGroupId = (globalId: ID) => {
  const [, id] = fromGlobalId(globalId);

  return {
    id,
  };
};

export const toCustomerId = (customerId: string | number) =>
  toGlobalId("Customer", customerId);

export const fromCustomerId = (id: ID) => ({
  type: "Customer",
  customerId: fromGlobalId(id)[1],
});

export const toDealerId = (dealerId: string | number) =>
  toGlobalId("Dealer", dealerId);

export const fromDealerId = (id: ID) => ({
  type: "Dealer",
  dealerId: fromGlobalId(id)[1],
});

export const toDealerActivityId = (dealerId: number) =>
  toGlobalId("DealerActivity", dealerId);

export const fromDealerActivityId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  dealerId: Number(fromGlobalId(id)[1]),
});

export const toDealerKeypadContactInfoId = (
  contactInfoId: string | number,
  dealerId: string | number
) => toGlobalId("DealerKeypadContactInfo", contactInfoId, dealerId);

export const fromDealerKeypadContactInfoId = (id: ID) => ({
  type: "DealerKeypadContactInfo",
  contactInfoId: fromGlobalId(id)[1],
  dealerId: fromGlobalId(id)[2],
});

export const toDealerUserSettingsId = (
  dealerId: string | number,
  personId: string | number,
  appType: DealerUserSettingsAppType
) => toGlobalId("DealerUserSettings", dealerId, personId, appType);

export const fromDealerUserSettingsId = (id: ID) => ({
  type: "DealerUserSettings",
  dealerId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
  appType: fromGlobalId(id)[3] as DealerUserSettingsAppType,
});

export const toDeviceInformationsId = (
  type: "Xt" | "Xr" | "Xf" | "Xt75",
  systemId: number | string,
  number: number | string
) => {
  const deviceType =
    type === "Xr"
      ? XrDeviceInformationsType
      : type === "Xf"
      ? XfDeviceInformationsType
      : type === "Xt75"
      ? Xt75DeviceInformationsType
      : XtDeviceInformationsType;

  const args = [
    deviceType,
    Number(systemId),
    Number(number),
    Date.now() +
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
  ];

  return toGlobalId(...args);
};

export const fromDeviceInformationsId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
    number: Number(number),
  };
};

export const toDevice734Id = (
  systemId: number | string,
  number: number | string
) => {
  return toGlobalId("Device734", systemId, number);
};

export const fromDevice734Id = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
    number: Number(number),
  };
};

export const XrDeviceInformationsType = "XrDeviceInformation";
export const XfDeviceInformationsType = "XfDeviceInformation";
export const Xt75DeviceInformationsType = "Xt75DeviceInformation";
export const XtDeviceInformationsType = "XtDeviceInformation";

export const toDoorId = (
  customerId: string | number,
  systemId: string | number,
  doorNumber: string | number,
  siteId?: string | number | null,
  siteDoorNumber?: string | number
) =>
  toGlobalId(
    "Door",
    customerId,
    systemId,
    doorNumber,
    siteId ?? "",
    siteDoorNumber ?? ""
  );

export const fromDoorId = (id: ID) => ({
  type: "Door",
  customerId: fromGlobalId(id)[1],
  systemId: fromGlobalId(id)[2],
  number: fromGlobalId(id)[3],
  siteId: fromGlobalId(id)[4] || undefined,
  siteDoorNumber: fromGlobalId(id)[5] || undefined,
});

export const toDownloadableFileId = (
  dealerId: string | number,
  downloadableFileId: string | number,
  downloadableFileType: DownloadableFileType
) =>
  toGlobalId(
    "DownloadableFile",
    dealerId,
    downloadableFileId,
    downloadableFileType
  );

export const fromDownloadableFileId = (id: ID) => ({
  type: "DownloadableFile",
  dealerId: fromGlobalId(id)[1],
  downloadableFileId: fromGlobalId(id)[2],
  downloadableFileType: fromGlobalId(id)[3],
});

export const toEventId = (
  customerId: string | number,
  eventId: string | number
) => toGlobalId("Event", customerId, eventId);

export const fromEventId = (id: ID) => ({
  type: "Event",
  customerId: fromGlobalId(id)[1],
  eventId: fromGlobalId(id)[2],
});

export const toFavoriteId = (
  systemId: string | number,
  number: string | number
) => {
  return toGlobalId("Favorite", systemId, number);
};

export const fromFavoriteId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number,
  };
};

export const toGlobalHolidayDateId = (
  dealerId: string | number,
  number: string | number
) => toGlobalId("GlobalHolidayDate", dealerId, number);

export const fromGlobalHolidayDateId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  dealerId: fromGlobalId(id)[1],
  number: fromGlobalId(id)[2],
});

export const toGlobalSchedulerJobId = (
  dealerId: string | number,
  jobId: string | number
) => toGlobalId("GlobalSchedulerJobId", dealerId, jobId);

export const fromGlobalSchedulerJobId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  dealerId: fromGlobalId(id)[1],
  jobId: fromGlobalId(id)[2],
});

export const toAllGlobalSchedulerJobsId = (
  dealerId: string | number,
  jobId: string | number
) => toGlobalId("AllGlobalSchedulerJobsId", dealerId, jobId);

export const fromAllGlobalSchedulerJobsId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  dealerId: fromGlobalId(id)[1],
  jobId: fromGlobalId(id)[2],
});

export const toHolidayDateId = (
  systemId: string | number,
  number: string | number
) => toGlobalId("HolidayDate", systemId, number);

export const fromHolidayDateId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  systemId: fromGlobalId(id)[1],
  number: fromGlobalId(id)[2],
});

export const toIEnsoCameraId = (cameraId: string, systemId: string) =>
  toGlobalId("IEnsoCamera", systemId, cameraId);

export const fromIEnsoCameraId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  systemId: fromGlobalId(id)[1],
  cameraId: fromGlobalId(id)[2],
});

export const toKeyfobId = (
  systemId: number | string,
  number: number | string
) => {
  const args = [
    "Keyfob",
    systemId,
    Number(number),
    Date.now() +
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
  ];

  return toGlobalId(...args);
};

export const fromKeyfobId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number: Number(number),
  };
};

export const toKeypadId = (recordNumber: number, systemId: number) =>
  toGlobalId("Keypad", recordNumber, systemId);

export const fromKeypadId = (id: ID) => {
  const [type, recordNumber, systemId] = fromGlobalId(id);

  return {
    type,
    recordNumber,
    systemId,
  };
};

export const toLockoutCodeId = (systemId: number | string) =>
  toGlobalId("LockoutCode", Number(systemId));

export const fromLockoutCodeId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toMenuDisplayId = (systemId: string | number) => {
  return toGlobalId("MenuDisplay", systemId);
};

export const fromMenuDisplayId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toMessagingSetupId = (systemId: number | string) =>
  toGlobalId("MessagingSetup", Number(systemId));

export const fromMessagingSetupId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toMobileCredentialHistoryEventId = (
  dealerId: number | string,
  id: number | string
) => toGlobalId("MobileCredentialIssuedHistoryEvent", dealerId, id);

export const fromMobileCredentialHistoryEventId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  dealerId: fromGlobalId(id)[1],
  id: fromGlobalId(id)[2],
});

export const toNetworkOptionsId = (systemId: number | string) =>
  toGlobalId("NetworkOptions", Number(systemId));

export const fromNetworkOptionsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toOutputGroupId = (
  systemId: number | string,
  number: number | string
) => {
  const args = [
    "OutputGroup",
    systemId,
    Number(number),
    Date.now() +
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
  ];

  return toGlobalId(...args);
};

export const fromOutputGroupId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number: Number(number),
  };
};

export const toOutputOptionsId = (systemId: number | string) =>
  toGlobalId("OutputOptions", Number(systemId));

export const fromOutputOptionsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toOutputOptionId = (systemId: number | string) =>
  toGlobalId("OutputOption", Number(systemId));

export const fromOutputOptionId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toUnprogrammedFavoriteId = (
  systemId: string | number,
  number: string | number
) => {
  return toGlobalId("UnprogrammedFavorite", systemId, number);
};

export const fromUnprogrammedFavoriteId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number,
  };
};

export const toUnprogrammedOutputId = (
  systemId: string | number,
  number: string | number
) => {
  return toGlobalId("UnprogrammedOutput", systemId, number);
};

export const fromUnprogrammedOutputId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number,
  };
};

export const toOutputId = (
  systemId: string | number,
  outputNumber: string | number
) => {
  const args = [
    "Output",
    systemId,
    outputNumber,
    Date.now() +
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
  ];

  return toGlobalId(...args);
};

export const fromOutputId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number,
  };
};

export const toPanelCapabilitiesId = (systemId: string) =>
  toGlobalId("PanelCapabilities", systemId);

export const fromPanelCapabilitiesId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  systemId: String(fromGlobalId(id)[1]),
});

export const toPcLogReportsId = (systemId: string | number) => {
  return toGlobalId("PcLogReports", systemId);
};

export const fromPcLogReportsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toGroupId = (profileNumber: string, systemId: string) =>
  toGlobalId("Group", profileNumber, systemId);

export const fromGroupId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  profileNumber: String(fromGlobalId(id)[1]),
  systemId: String(fromGlobalId(id)[2]),
});

export const toProfileId = (profileNumber: string, systemId: string) =>
  toGlobalId("Profile", profileNumber, systemId);

export const fromProfileId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  profileNumber: String(fromGlobalId(id)[1]),
  systemId: String(fromGlobalId(id)[2]),
});

export const toReceiverId = (
  dealerId: number | string,
  receiverId: number | string
) => {
  return toGlobalId("Receiver", dealerId, receiverId);
};

export const fromReceiverId = (id: ID) => {
  const [type, dealerId, receiverId] = fromGlobalId(id);

  return {
    type,
    dealerId,
    receiverId,
  };
};

export const toRemoteOptionsId = (
  systemId: number | string,
  type: "Xt" | "Xt75" | "Xr" | "Xf"
) =>
  toGlobalId(
    type === "Xr"
      ? XrRemoteOptionsType
      : type === "Xf"
      ? XfRemoteOptionsType
      : type === "Xt75"
      ? Xt75RemoteOptionsType
      : XtRemoteOptionsType,
    Number(systemId)
  );

export const fromRemoteOptionsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const XrRemoteOptionsType = "XrRemoteOptions";
export const XfRemoteOptionsType = "XfRemoteOptions";
export const Xt75RemoteOptionsType = "Xt75RemoteOptions";
export const XtRemoteOptionsType = "XtRemoteOptions";
export const toReportId = (
  customerId: string | number,
  savedReportId: string | number
) => toGlobalId("Report", customerId, savedReportId);

export const fromReportId = (id: ID) => {
  const [type, customerId, savedReportId] = fromGlobalId(id);

  return {
    type,
    customerId,
    savedReportId,
  };
};

export const toControlSystemScheduleId = (
  systemId: string | number,
  scheduleNumber: string | number
) => toGlobalId("ControlSystemSchedule", systemId, scheduleNumber);

export const fromControlSystemScheduleId = (id: ID) => ({
  type: "ControlSystemSchedule",
  systemId: fromGlobalId(id)[1],
  scheduleNumber: fromGlobalId(id)[2],
});

export const toSecurityGradeId = (systemId: number | string) =>
  toGlobalId("SecurityGrade", Number(systemId));

export const fromSecurityGradeId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toSharedClientConfigurationId = (
  sharedClientConfigId: number | string,
  userId: number | string,
  controlSystemId?: number | string,
  siteId?: number | string
) =>
  toGlobalId(
    "SharedClientConfiguration",
    userId,
    sharedClientConfigId,
    controlSystemId ?? "",
    siteId ?? ""
  );

export const fromSharedClientConfigurationId = (id: ID) => ({
  type: "SharedClientConfiguration",
  userId: fromGlobalId(id)[1],
  sharedClientConfigId: fromGlobalId(id)[2],
  controlSystemId: fromGlobalId(id)[3],
  siteId: fromGlobalId(id)[4],
});

export const toSiteControlSystemId = (
  customerId: string | number,
  siteId: string | number,
  controlSystemId: string | number
) => toGlobalId("SiteControlSystem", customerId, siteId, controlSystemId);

export const fromSiteControlSystemId = (id: ID) => ({
  type: "SiteControlSystem",
  customerId: fromGlobalId(id)[1],
  siteId: fromGlobalId(id)[2],
  controlSystemId: fromGlobalId(id)[3],
});

export const toSiteDoorId = ({
  customerId,
  systemId,
  doorNumber,
  siteId,
  siteDoorNumber,
}: {
  customerId: string | number;
  systemId: string | number;
  doorNumber: string | number;
  siteId: string | number;
  siteDoorNumber: string | number;
}) =>
  toGlobalId(
    "SiteDoor",
    customerId,
    systemId,
    doorNumber,
    siteId,
    siteDoorNumber
  );

export const fromSiteDoorId = (id: ID) => ({
  type: "SiteDoor",
  customerId: fromGlobalId(id)[1],
  systemId: fromGlobalId(id)[2],
  number: fromGlobalId(id)[3],
  siteId: fromGlobalId(id)[4],
  siteDoorNumber: fromGlobalId(id)[5],
});

export const toSiteHistoryEventId = (
  siteId: string | number,
  eventId: string | number,
  eventAt: string
) =>
  toGlobalId("SiteHistoryEvent", siteId, eventId, eventAt.replace(/:/gim, "."));

export const fromSiteHistoryEventId = (id: ID) => ({
  type: "SiteHistoryEvent",
  siteId: fromGlobalId(id)[1],
  eventId: fromGlobalId(id)[2],
  eventAt: fromGlobalId(id)[3].replace(/\./gim, ":"),
});

export const toSiteReportEventId = (
  siteId: string | number,
  eventId: string | number,
  subcategoryId: string | number
) => toGlobalId("SiteReportEvent", siteId, eventId, subcategoryId);

export const fromSiteReportEventId = (id: ID) => {
  const [siteId, eventId, subcategoryId] = fromGlobalId(id);

  return {
    type: "SiteReportEvent",
    siteId,
    eventId,
    subcategoryId,
  };
};

export const toSiteGroupId = (
  customerId: string | number,
  siteId: string | number,
  linkedProfileId: string | number
) => toGlobalId("SiteGroup", customerId, siteId, linkedProfileId);

export const fromSiteGroupId = (id: ID) => {
  const [type, customerId, siteId, linkedProfileId] = fromGlobalId(id);

  return {
    type,
    customerId,
    siteId,
    linkedProfileId,
  };
};

export const toSiteNotificationId = ({
  siteId,
  controlSystemId,
  notificationId,
}: {
  siteId: string | number;
  controlSystemId: string | number;
  notificationId: string | number;
}) => toGlobalId("SiteNotification", siteId, controlSystemId, notificationId);

export const fromSiteNotificationId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  siteId: fromGlobalId(id)[1],
  controlSystemId: fromGlobalId(id)[2],
  notificationId: fromGlobalId(id)[3],
});

export const toSiteOutputId = (
  controlSystemId: string | number,
  outputNumber: string | number,
  siteOutputNumber: string | number | null
) =>
  toGlobalId(
    "SiteOutput",
    controlSystemId,
    Number(outputNumber),
    siteOutputNumber ? Number(siteOutputNumber) : "null"
  );

export const fromSiteOutputId = (id: ID) => ({
  type: "SiteOutput",
  controlSystemId: fromGlobalId(id)[1],
  outputNumber: fromGlobalId(id)[2],

  siteOutputNumber: pipe(fromGlobalId(id)[3], (siteOutputNumber) =>
    siteOutputNumber === "null" ? null : siteOutputNumber
  ),
});

export const toSiteOutputModuleId = (
  controlSystemId: string | number,
  outputModuleId: string | number
) => toGlobalId("SiteOutputModule", controlSystemId, outputModuleId);

export const fromSiteOutputModuleId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  controlSystemId: fromGlobalId(id)[1],
  outputModuleId: fromGlobalId(id)[2],
});

export const toSiteReportId = (
  customerId: string | number,
  siteId: string | number,
  savedReportId: string | number
) => toGlobalId("SiteReport", customerId, siteId, savedReportId);

export const fromSiteReportId = (id: ID) => {
  const [type, customerId, siteId, savedReportId] = fromGlobalId(id);

  return {
    type,
    customerId,
    siteId,
    savedReportId,
  };
};

export const toRuleId = (
  customerId: string | number,
  siteId: string | number,
  userActionId: string | number,
  eventId: string | number
) => toGlobalId("Rule", customerId, siteId, userActionId, eventId);

export const fromRuleId = (id: ID) => ({
  type: "Rule",
  customerId: fromGlobalId(id)[1],
  siteId: fromGlobalId(id)[2],
  userActionId: fromGlobalId(id)[3],
  eventId: fromGlobalId(id)[4],
});

export const toSiteGroupScheduleId = (
  customerId: string | number,
  siteId: string | number,
  linkedProfileId: string | number,
  linkedTimeScheduleId: string | number
) =>
  toGlobalId(
    "SiteGroupSchedule",
    customerId,
    siteId,
    linkedProfileId,
    linkedTimeScheduleId
  );

export const fromSiteGroupScheduleId = (id: ID) => ({
  type: "SiteGroupSchedule",
  customerId: fromGlobalId(id)[1],
  siteId: fromGlobalId(id)[2],
  linkedProfileId: fromGlobalId(id)[3],
  linkedTimeScheduleId: fromGlobalId(id)[4],
});

export const toDoorScheduleId = (
  customerId: string | number,
  siteId: string | number,
  linkedTimeScheduleId: string | number
) => toGlobalId("SiteDoorSchedule", customerId, siteId, linkedTimeScheduleId);

export const fromDoorScheduleId = (id: ID) => ({
  type: "SiteDoorSchedule",
  customerId: fromGlobalId(id)[1],
  siteId: fromGlobalId(id)[2],
  linkedTimeScheduleId: fromGlobalId(id)[3],
});

export const toSiteId = (
  customerId: string | number,
  siteId: string | number
) => toGlobalId("Site", customerId, siteId);

export const fromSiteId = (id: ID) => ({
  type: "Site",
  customerId: fromGlobalId(id)[1],
  siteId: fromGlobalId(id)[2],
});

export const toSiteCombinedEventId = (
  systemId: string | number,
  eventId: string | number
) => toGlobalId("SiteCombinedEvent", systemId, eventId);

export const fromSiteCombinedEventId = (id: ID) => ({
  type: "SiteCombinedEvent",
  systemId: fromGlobalId(id)[1],
  eventId: fromGlobalId(id)[2],
});

export const toStatusListDisplayId = (systemId: string | number) => {
  return toGlobalId("StatusListDisplay", systemId);
};

export const fromStatusListDisplayId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toPanelResetStatisticId = (
  resetType: PanelResetType,
  id: string | number
) => toGlobalId("PanelResetStatistic", resetType, id);

export const fromPanelResetStatisticId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  resetType: Number(fromGlobalId(id)[1]) as any as PanelResetType,
  id: fromGlobalId(id)[2],
});

export const toPanelResetInformationId = (resetType: PanelResetType) =>
  toGlobalId("PanelResetInformation", resetType);

export const fromPanelResetInformationId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  resetType: Number(fromGlobalId(id)[1]) as any as PanelResetType,
});

export const toSystemOptionsId = (
  type: "Xt" | "Xt75" | "Xr" | "Xf" | "TMSentry",
  systemId: number | string,
  isNew?: boolean
) => {
  const args = [
    type === "Xr"
      ? "XrSystemOptions"
      : type === "Xt"
      ? "XtSystemOptions"
      : type === "Xt75"
      ? "Xt75SystemOptions"
      : type === "TMSentry"
      ? "TMSentrySystemOptions"
      : "XfSystemOptions",
    Number(systemId),
  ];

  return isNew ? toGlobalId(...args, Date.now()) : toGlobalId(...args);
};

export const fromSystemOptionsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toSystemReportsId = (systemId: number | string) =>
  toGlobalId("SystemReports", Number(systemId));

export const fromSystemReportsId = (id: ID) => {
  const [type, systemId] = fromGlobalId(id);

  return {
    type,
    systemId: Number(systemId),
  };
};

export const toTagId = (tagId: string | number, dealerId: string | number) =>
  toGlobalId("Tag", tagId, dealerId);

export const fromTagId = (id: ID) => ({
  type: "Tag",
  tagId: fromGlobalId(id)[1],
  dealerId: fromGlobalId(id)[2],
});

export const toProgrammingTemplateId = (
  templateId: string | number,
  dealerId: string | number
) => toGlobalId("ProgrammingTemplate", templateId, dealerId);

export const fromProgrammingTemplateId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  id: fromGlobalId(id)[1],
  dealerId: fromGlobalId(id)[2],
});

export const toProgrammingTemplateConceptId = (
  id: string | number,
  concept: string,
  itemNumber?: string | number
) => {
  return isNotNullOrUndefined(itemNumber)
    ? toGlobalId("ProgrammingTemplateConcept", id, concept, itemNumber)
    : toGlobalId("ProgrammingTemplateConcept", id, concept);
};

export const fromProgrammingTemplateConceptId = (id: ID) => ({
  type: "ProgrammingTemplateConcept",
  id: fromGlobalId(id)[1],
  concept: fromGlobalId(id)[2],
  itemNumber: fromGlobalId(id)[3],
});

export const toCredentialId = ({
  customerId,
  personId,
  credentialId,
}: {
  customerId: string | number;
  personId: string | number;
  credentialId: string | number;
}) => toGlobalId("Credential", customerId, personId, credentialId);

export const fromCredentialId = (id: ID) => ({
  type: "Credential",
  customerId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
  credentialId: fromGlobalId(id)[3],
});

export const toCredentialSummaryId = ({
  customerId,
}: {
  customerId: string | number;
}) => toGlobalId("CredentialSummary", customerId);

export const fromCredentialSummaryId = (id: ID) => ({
  type: "CredentialSummary",
  customerId: fromGlobalId(id)[1],
});

export const toPanelPersonId = ({
  panelId,
  personId,
  customerId,
}: {
  panelId: string | number;
  personId: string | number;
  customerId: string | number;
}) => toGlobalId("PanelPerson", panelId, personId, customerId);

export const fromPanelPersonId = (id: ID) => ({
  type: "PanelPerson",
  panelId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
  customerId: fromGlobalId(id)[3],
});

export const toQuickPanelPersonId = ({
  personId,
}: {
  personId: string | number;
}) => toGlobalId("QuickPanelPerson", personId);

export const fromQuickPanelPersonId = (id: ID) => ({
  type: "QuickPanelPerson",
  personId: fromGlobalId(id)[1],
});

export const toQuickSitePersonId = ({
  customerId,
  siteId,
  personId,
}: {
  customerId: string | number;
  siteId: string | number;
  personId: string | number;
}) => toGlobalId("QuickSitePerson", customerId, siteId, personId);

export const fromQuickSitePersonId = (id: ID) => ({
  type: "QuickSitePerson",
  customerId: fromGlobalId(id)[1],
  siteId: fromGlobalId(id)[2],
  personId: fromGlobalId(id)[3],
});

export const toCustomerPersonId = ({
  customerId,
  personId,
}: {
  customerId: string | number;
  personId: string | number;
}) => toGlobalId("CustomerPerson", customerId, personId);

export const fromCustomerPersonId = (id: ID) => ({
  type: "CustomerPerson",
  customerId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
});

export const toQuickCustomerPersonId = ({
  customerId,
  personId,
}: {
  customerId: string | number;
  personId: string | number;
}) => toGlobalId("QuickCustomerPerson", customerId, personId);

export const fromQuickCustomerPersonId = (id: ID) => ({
  type: "QuickCustomerPerson",
  customerId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
});

export const fromQuickSitePersonIdToCustomerPersonId = (id: ID) => {
  if (fromGlobalId(id)[0] === "CustomerPerson") return id;

  const { customerId, personId } = fromQuickSitePersonId(id);

  return toCustomerPersonId({
    customerId,
    personId,
  });
};

export const fromCustomerPersonIdToQuickSitePersonId = (
  id: ID,
  siteId: string | number
) => {
  const { customerId, personId } = fromCustomerPersonId(id);

  return toQuickSitePersonId({
    customerId,
    siteId,
    personId,
  });
};

export const toDealerPersonId = ({
  dealerId,
  userId,
}: {
  dealerId: string | number;
  userId: string | number;
}) => toGlobalId("DealerPerson", dealerId, userId);

export const fromDealerPersonId = (id: ID) => ({
  type: "DealerPerson",
  dealerId: fromGlobalId(id)[1],
  userId: fromGlobalId(id)[2],
});

export const toSupervisorPersonId = ({
  personId,
}: {
  personId: string | number;
}) => toGlobalId("SupervisorPerson", personId);

export const fromSupervisorPersonId = (id: ID) => ({
  type: "SupervisorPerson",
  personId: fromGlobalId(id)[1],
});

export const toCustomerUserId = (
  customerId: string | number,
  personId: string | number,
  userId: string | number
) => toGlobalId("CustomerUser", customerId, personId, userId);

export const fromCustomerUserId = (id: ID) => ({
  type: "CustomerUser",
  customerId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
  userId: fromGlobalId(id)[3],
});

export const toDealerUserId = (
  dealer: string | number,
  userId: string | number
) => toGlobalId("DealerUser", dealer, userId);

export const fromDealerUserId = (id: ID) => ({
  type: "DealerUser",
  dealerId: fromGlobalId(id)[1],
  userId: fromGlobalId(id)[2],
});

export const toSupervisorUserId = (userId: string | number) =>
  toGlobalId("SupervisorUser", userId);

export const fromSupervisorUserId = (id: ID) => ({
  type: "SupervisorUser",
  userId: fromGlobalId(id)[1],
});

export const toUserPermissionsId = (
  userId: string | number,
  id: string | number
) => toGlobalId("UserPermissions", userId, id);

export const fromUserPermissionsId = (globalId: ID) => {
  const [type, userId, id] = fromGlobalId(globalId);

  return {
    type,
    userId,
    id,
  };
};

export const toPerformantAppUserId = (
  customerId: string | number,
  personId: string | number,
  userId: string | number
) => toGlobalId("PerformantAppUser", customerId, personId, userId);

export const fromPerformantAppUserId = (id: ID) => ({
  type: "PerformantAppUser",
  customerId: fromGlobalId(id)[1],
  personId: fromGlobalId(id)[2],
  userId: fromGlobalId(id)[3],
});

export const toBulkUserCodeImportId = (
  systemType: BulkUserCodeImportSystemType,
  systemOrSiteId: string,
  jobId: string | number
) => toGlobalId("BulkUserCodeImport", systemType, systemOrSiteId, jobId);

export const fromBulkUserCodeImportId = (id: ID) => ({
  type: "BulkUserCodeImport",
  systemType: fromGlobalId(id)[1],
  systemOrSiteId: fromGlobalId(id)[2],
  jobId: fromGlobalId(id)[3],
});

export const toUserCodeId = (
  systemId: string | number,
  userCodeNumber: string | number,
  hardwareFamily: string
) => {
  const type = resolveUserCodeNodeType(hardwareFamily);
  return toGlobalId(type, systemId, userCodeNumber);
};

export const fromUserCodeId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  systemId: fromGlobalId(id)[1],
  number: fromGlobalId(id)[2],
});

export const toPanelUserSecurityCodeDetailsId = ({
  id,
}: {
  id: string | number;
}) => toGlobalId("PanelUserSecurityCodeDetails", id);

export const fromPanelUserSecurityCodeDetailsId = (id: ID) => ({
  type: "PanelUserSecurityCodeDetails",
  id: fromGlobalId(id)[1],
});

export const resolveUserCodeNodeType = (hardwareFamily: string) => {
  switch (hardwareFamily) {
    case "XR550":
      return XR_USER_CODE_NODE_TYPE;
    case "XF6":
      return XF_USER_CODE_NODE_TYPE;
    case "XT30":
    default:
      return XT_USER_CODE_NODE_TYPE;
  }
};

export const toVkSystemUserId = ({
  systemId,
  personId,
}: {
  systemId: string | number;
  personId: string | number;
}) => toGlobalId("VkSystemUser", systemId, personId);

export const fromVkSystemUserId = (id: ID) => ({
  type: String(fromGlobalId(id)[0]),
  systemId: Number(fromGlobalId(id)[1]),
  personId: Number(fromGlobalId(id)[2]),
});

export const toVarHubId = (systemId: number, hubId: number) =>
  toGlobalId("VarHub", systemId, hubId);

export const fromVarHubId = (id: ID) => ({
  type: "VarHub",
  systemId: fromGlobalId(id)[1],
  hubId: fromGlobalId(id)[2],
});

export const toVarHubSettingsId = (systemId: number, hubId: number) =>
  toGlobalId("VarHubSettings", systemId, hubId);

export const fromVarHubSettingsId = (id: ID) => ({
  type: "VarHubSettings",
  systemId: fromGlobalId(id)[1],
  hubId: fromGlobalId(id)[2],
});

export const toVarHubCameraDetectionRegionId = (
  systemId: number | string,
  cameraId: number | string,
  regionId: number | string
) => toGlobalId("VarHubCameraDetectionRegion", systemId, cameraId, regionId);

export const fromVarHubCameraDetectionRegionId = (id: ID) => {
  const [type, systemId, cameraId, regionId] = fromGlobalId(id);

  return {
    type,
    systemId,
    cameraId,
    regionId,
  };
};

export const toVarHubCameraId = (
  systemId: string | number,
  hubId: string | number,
  camectCameraId: string | number
) => {
  return toGlobalId("VarHubCamera", systemId, hubId, camectCameraId);
};

export const fromVarHubCameraId = (id: ID) => {
  const [type, systemId, hubId, camectCameraId] = fromGlobalId(id);

  return {
    type,
    systemId,
    hubId,
    camectCameraId,
  };
};

export const toVarConnectedCameraId = (
  systemId: string | number,
  hubId: string | number,
  camectCameraId: string | number
) => {
  return toGlobalId("VarConnectedCamera", systemId, hubId, camectCameraId);
};

export const fromVarConnectedCameraId = (id: ID) => {
  const [type, systemId, hubId, camectCameraId] = fromGlobalId(id);

  return {
    type,
    systemId,
    hubId,
    camectCameraId,
  };
};

export const toVarVideoActionId = (systemId: number, videoActionId: number) =>
  toGlobalId("VarVideoAction", systemId, videoActionId);

export const fromVarVideoActionId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  systemId: fromGlobalId(id)[1],
  varVideoActionId: fromGlobalId(id)[2],
});

export const toAllCamerasId = (
  systemId: number,
  type: string,
  deviceId: number,
  cameraId: number
) => toGlobalId("AllCameras", type, systemId, deviceId, cameraId);

export const fromAllCamerasId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  systemId: fromGlobalId(id)[1],
  videoDeviceId: fromGlobalId(id)[2],
});

export const toStreamDetailsId = (
  type: "VSCameraDetails" | "ScapiCameraDetails" | "IEnsoCameraDetails",
  deviceId: number,
  cameraId: number
) => toGlobalId(type, deviceId, cameraId);

export const fromStreamDetailsId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  deviceId: fromGlobalId(id)[1],
  cameraId: fromGlobalId(id)[2],
});

export const toVideoSecureComDoorbellId = (
  type: "SecureComDoorbell",
  deviceId: string | number,
  controlSystemId: string | number
) => toGlobalId(type, deviceId, controlSystemId);

export const fromVideoSecureComDoorbellId = (id: ID) => {
  const [type, deviceId, controlSystemId] = fromGlobalId(id);

  return {
    type,
    deviceId,
    controlSystemId,
  } as {
    type: "SecureComDoorbell";
    deviceId: string;
    controlSystemId: string;
  };
};

export const toVideoChannelId = (
  type: VideoChannelType,
  deviceId: string | number,
  videoChannelId: string | number
) => toGlobalId(type, deviceId, videoChannelId);

export const fromVideoChannelId = (videoDeviceId: ID) => ({
  type: fromGlobalId(videoDeviceId)[0] as VideoChannelType,
  deviceId: fromGlobalId(videoDeviceId)[1],
  videoChannelId: fromGlobalId(videoDeviceId)[2],
});

export const toVideoRecorderId = (
  type: VideoRecorderType,
  deviceId: string | number
) => toGlobalId(type, deviceId);

export const fromVideoRecorderId = (videoDeviceId: ID) => ({
  type: fromGlobalId(videoDeviceId)[0] as VideoRecorderType,
  deviceId: fromGlobalId(videoDeviceId)[1],
});

export const toDWSpectrumId = (
  type: VideoRecorderType,
  deviceId: string | number,
  controlSystemId: number
) => toGlobalId(type, deviceId, controlSystemId);

export const fromDWSpectrumId = (videoDeviceId: ID) => ({
  type: fromGlobalId(videoDeviceId)[0] as VideoRecorderType,
  deviceId: fromGlobalId(videoDeviceId)[1],
  controlSystemId: fromGlobalId(videoDeviceId)[2],
});

type VideoChannelType =
  | "DigitalWatchdogCamera"
  | "HanwhaNvrVideoChannel"
  | "SecureComCamera"
  | "SecureComNvrVideoChannel"
  | "EagleEyeBridgeVideoChannel"
  | "HikvisionNvrVideoChannel"
  | "OpenEyeNvrVideoChannel";
type VideoRecorderType =
  | "OpenEyeNvr"
  | "EagleEyeBridge"
  | "SecureComNvr"
  | "HikvisionNvr"
  | "DigitalWatchdogSpectrumNvr"
  | "HanwhaNvr";
export const toVideoActionId = (systemId: number, videoActionId: number) =>
  toGlobalId("VideoAction", systemId, videoActionId);

export const fromVideoActionId = (id: ID) => ({
  type: fromGlobalId(id)[0],
  systemId: fromGlobalId(id)[1],
  videoActionId: fromGlobalId(id)[2],
});

export const toZoneId = (
  systemId: string | number,
  number: string | number
) => {
  const args = [
    "Zone",
    systemId,
    number,
    Date.now() +
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
  ];

  return toGlobalId(...args);
};

export const fromZoneId = (id: ID) => {
  const [type, systemId, number] = fromGlobalId(id);

  return {
    type,
    systemId,
    number,
  };
};

export const toZwaveNodeId = (
  zwaveNodeType: ZwaveNodeType,
  systemId: number | string,
  number: number | string
) => toGlobalId(zwaveNodeType, Number(systemId), Number(number));

export const fromZwaveNodeId = (id: ID) => ({
  type: fromGlobalId(id)[0] as ZwaveNodeType,
  systemId: fromGlobalId(id)[1],
  number: fromGlobalId(id)[2],
});

export type ZwaveNodeType = "ZwaveBarrierOperator" | "ZwaveLock";
